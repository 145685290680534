import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Icon } from '@hubportal/components';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardItem,
  CardRow,
  CardRowItem,
  StatusDot,
  Heading,
  CardEmptyItem,
  CardSkeleton,
  CardError,
} from 'components/Card';
import { getStuffStates } from 'utils/network/apis';

const OpsAssociates = (props: { hub: string }): JSX.Element => {
  const { hub } = props;
  const { t } = useTranslation();

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['ops'],
    queryFn: () => {
      return getStuffStates(hub);
    },
    refetchInterval: 10000,
  });

  const cards = useMemo(() => {
    if (!data) {
      return [];
    }

    return [
      {
        icon: 'inbounding',
        title: t('ops.inbounding.title'),
        emptyText: t('ops.inbounding.inactive'),
        data: data.inbounding,
      },
      {
        icon: 'inventory',
        title: t('ops.inventory.title'),
        emptyText: t('ops.inventory.inactive'),
        data: data.inventory,
      },
      {
        icon: 'picking',
        title: t('ops.picking.title'),
        emptyText: t('ops.picking.inactive'),
        data: data.picking,
      },
      {
        icon: 'restocking',
        title: t('ops.restocking.title'),
        emptyText: t('ops.restocking.inactive'),
        data: data.restocking,
      },
      {
        icon: 'hubActivities',
        title: t('ops.hub_activities.title'),
        emptyText: t('ops.hub_activities.inactive'),
        data: data.hub_activities,
      },
      {
        icon: 'idle',
        title: t('ops.idle.title'),
        emptyText: t('ops.idle.inactive'),
        data: data.idle,
      },
    ];
  }, [data]);

  const getActive = (): number => {
    if (!data) {
      return 0;
    }

    const { inbounding, inventory, picking, idle } = data;

    const active = [inbounding, inventory, picking, idle]
      .map((arr) => arr.length)
      .reduce((sum: number, len: number) => sum + len, 0);

    return active;
  };

  if (isLoading) {
    return (
      <div className="w-[300px] h-[700px]">
        <CardSkeleton />
      </div>
    );
  }

  if (error) {
    const onClick = (): Promise<any> => refetch();

    return (
      <div className="w-[300px] h-[700px]">
        <CardError onClick={onClick} />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-[367px]">
      <Heading title={t('ops.title')}>
        <div className="flex items-center h-6 detail-l text-white font-flink rounded-full p-2 bg-[#23272A]">
          <StatusDot color={getActive() ? '#59BEAA' : undefined} />
          {getActive()} {t('ops.active')}
        </div>
      </Heading>

      <div className="bg-[#23272A] overflow-y-auto rounded-[10px] mt-2 p-4 h-[696px]">
        {cards.map((card, i) => {
          return (
            <Card key={i}>
              <CardItem
                icon={
                  <Icon
                    type={
                      card.icon as
                        | 'inbounding'
                        | 'inventory'
                        | 'picking'
                        | 'idle'
                    }
                  />
                }
                item={card.title}
                value={card.data.length}
                isPickingSpeedEnabled={true}
              />
              <CardRow>
                {card.data.map((item) => {
                  return (
                    <CardRowItem
                      key={item.employee_number}
                      item={`${item.short_name}.`}
                      value={item.in_state_since}
                      pickingSpeed={
                        item.avg_picking_speed_per_item_in_seconds ?? 0
                      }
                      isPickingSpeedEnabled={true}
                    />
                  );
                })}
                {!card.data.length && <CardEmptyItem text={card.emptyText} />}
              </CardRow>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default OpsAssociates;
