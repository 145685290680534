import { Icon, ProgressBar, ProgressBarProps } from '@hubportal/components';
import clsx from 'clsx';
import { TooltipHeader } from 'components/TooltipHeader';
import { useTranslation } from 'react-i18next';
import useTimerEffect from 'src/hooks/useTimerEffect';
import { ChecksTypes } from 'utils/constants';

type CheckProps = {
  remainingChecksCount?: number;
  minsAgoCreated?: number;
  type: ChecksTypes;
  completedPercentage?: number;
  completedChecksCount?: number;
  isDeadlinePassed?: boolean;
  oldestTaskCreatedDate?: string;
};

// TODO: Add bg colors to tailwind config

enum sectionColors {
  stock_check_P0 = 'bg-[#FFB063]',
  fresh_checks = 'bg-[#D0EFEA]',
  bbd_checks = 'bg-[#E5DBF3]',
  stock_check_P1 = 'bg-[#FFE8D0]',
  manual_check = 'bg-[#DEDEDE]',
}

export const ChecksBudget = (props: { type: string }): JSX.Element => {
  const { t } = useTranslation();
  const { type } = props;
  return (
    <span
      className={clsx(
        'py-1.5 px-2 text-[#23272A] rounded-lg',
        type === ChecksTypes.STOCK_CHECK_P0 && sectionColors.stock_check_P0,
        type === ChecksTypes.FRESH_CHECK && sectionColors.fresh_checks,
        type === ChecksTypes.BBD_CHECK && sectionColors.bbd_checks,
        type === ChecksTypes.STOCK_CHECK_P1 && sectionColors.stock_check_P1,
        type === ChecksTypes.MANUAL_CHECK && sectionColors.manual_check
      )}
    >
      {t(`checks.${type}`)}
    </span>
  );
};

const ChecksCard = ({
  remainingChecksCount,
  type,
  completedPercentage,
  completedChecksCount,
  isDeadlinePassed,
  oldestTaskCreatedDate,
}: CheckProps): JSX.Element => {
  const { t } = useTranslation();
  let createdAgo: number = 0;
  let unixTime: number = 0;

  const { timeDiff, formatTime } = useTimerEffect(
    Math.floor(new Date(oldestTaskCreatedDate ?? 0).getTime() / 1000)
  );

  if (type === ChecksTypes.STOCK_CHECK_P0) {
    createdAgo = Math.floor(timeDiff / 60);
    unixTime = Math.floor(
      new Date(oldestTaskCreatedDate ?? 0).getTime() / 1000
    );
  }

  return (
    <div
      className={clsx(
        'px-2 py-4 bg-[#3A4045] mb-2 rounded-lg flex justify-between items-center h-16 text-xs',
        (type === ChecksTypes.STOCK_CHECK_P0 || isDeadlinePassed) &&
          'border-2 border-[#FFB063]'
      )}
    >
      <ChecksBudget type={type} />
      <div className="flex items-center justify-between">
        <div className="mr-4 items-left">
          {type !== ChecksTypes.MANUAL_CHECK ? (
            <div>
              <span className="text-[#F2F2F2]">
                {remainingChecksCount} {t('checks.remaining')}
              </span>
            </div>
          ) : (
            <div>
              <span className="text-[#F2F2F2]">
                {completedChecksCount} {t('checks.completed')}
              </span>
            </div>
          )}
        </div>
        <div className="w-[160px] flex items-center">
          {type !== ChecksTypes.MANUAL_CHECK &&
            type !== ChecksTypes.STOCK_CHECK_P0 && (
              <>
                <div className="w-full">
                  <ProgressBar
                    width={completedPercentage as ProgressBarProps['width']}
                    color={clsx(
                      completedPercentage === 100
                        ? 'bg-[#66C8BA]'
                        : 'bg-[#FFE8D0]'
                    )}
                    height={2}
                    backgroundColor="bg-[#23272A]"
                  />
                </div>

                <span className="ml-2 text-right text-sm">
                  {completedPercentage}%
                </span>

                {isDeadlinePassed && completedPercentage !== 100 && (
                  <div className="ml-2">
                    <TooltipHeader
                      label=""
                      info={t('checks.warning.overdue')}
                    />
                  </div>
                )}

                {completedPercentage === 100 && (
                  <span className="ml-2">
                    <Icon type={'checkBudget'} />
                  </span>
                )}
              </>
            )}

          {type === ChecksTypes.STOCK_CHECK_P0 && (
            <div className="w-full flex justify-end items-center">
              <span
                className={clsx(
                  'mr-2',
                  createdAgo > 30 ? 'text-[#FFB063]' : 'text-white'
                )}
              >
                {formatTime(unixTime)} ago
              </span>
              {createdAgo > 30 && (
                <TooltipHeader label="" info={t('checks.warning.overdue')} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChecksCard;
