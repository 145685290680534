import { FEATURE_FLAGS } from './flags';

function parseFeatureFlag(flag) {
  try {
    return JSON.parse(flag);
  } catch (error) {
    return null;
  }
}

function getLiveSteeringFlag(flags) {
  if (
    Object.keys(flags).length &&
    flags.decisions[FEATURE_FLAGS.LIVE_STEERING]
  ) {
    return parseFeatureFlag(
      flags.decisions[FEATURE_FLAGS.LIVE_STEERING].variation
    );
  }

  return null;
}

export const getActiveRiderColumns = (flags) => {
  const liveSteeringFlag = getLiveSteeringFlag(flags);
  return liveSteeringFlag ? liveSteeringFlag.riders : [];
};

export const getActiveOpsAssociateColumns = (flags) => {
  const liveSteeringFlag = getLiveSteeringFlag(flags);
  return liveSteeringFlag ? liveSteeringFlag.ops_associates : [];
};

export const isEppoHubMetricsEnabled = (flags) => {
  const liveSteeringFlag = getLiveSteeringFlag(flags);
  if (!liveSteeringFlag) {
    return true;
  }
  return liveSteeringFlag.hub_level === 'true';
};

export const isEppoEmployeeLevelMetricsEnabled = (flags) => {
  const liveSteeringFlag = getLiveSteeringFlag(flags);
  if (!liveSteeringFlag) {
    return false;
  }
  return liveSteeringFlag.employee_level === 'true';
};

export const isFeatureEnabledEppo = (
  allFlags,
  featureFlag,
  variant
): boolean => {
  if (Object.keys(allFlags).length && allFlags.decisions[featureFlag]) {
    const flagVariation = allFlags.decisions[featureFlag].variation;
    if (flagVariation && flagVariation === variant) {
      return true;
    }
  }
  return false;
};
