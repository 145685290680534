import { Heading } from 'components/Card';
import Checks from 'components/Checks';
import OpsAssociates from 'components/Ops';
import OpsAssociatesV2 from 'components/OpsAssociatesV2';
import RidersWidget from 'components/RidersWidget';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS, FEATURE_VARIANTS } from 'utils/eppo/flags';
import { isFeatureEnabledEppo } from 'utils/eppo/helpers';
import useStore from 'utils/store';

type WidgetViewProps = {
  hubSlug: string;
  authClient: string;
};

const WidgetView: React.FC<WidgetViewProps> = ({
  hubSlug,
  authClient,
}: WidgetViewProps) => {
  const { featureFlagDecisions } = useStore();
  const { t } = useTranslation();

  const isOpsAssociatesV2Enabled = useMemo(() => {
    return isFeatureEnabledEppo(
      featureFlagDecisions,
      FEATURE_FLAGS.OF_POOLS_WIDGET,
      FEATURE_VARIANTS.ON
    );
  }, [featureFlagDecisions]);

  return (
    <div className="ml-8 gap-7 flex flex-row mt-8">
      <Checks hub={hubSlug} />
      <OpsAssociates hub={hubSlug} />
      <RidersWidget authClient={authClient} />
      {isOpsAssociatesV2Enabled && (
        <div className="flex flex-col">
          <Heading title={t('ops.title')} />
          <OpsAssociatesV2 hub={hubSlug} />
        </div>
      )}
    </div>
  );
};

export default WidgetView;
