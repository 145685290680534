import { ProgressBar } from '@hubportal/components';
import { useQuery } from '@tanstack/react-query';
import { CardError, CardSkeleton, Heading } from 'components/Card';
import { getChecksRequests } from 'utils/network/apis';
import {
  calculatePercentage,
  findOldestTask,
  setChecksPayload,
  splitChecksData,
} from 'utils/helpers';
import { useEffect, useMemo, useState } from 'react';
import ChecksCard from './ChecksCard';
import {
  ChecksTypes,
  ChecksDeadlineTypes,
  ChecksSections,
} from 'utils/constants';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const Checks = ({ hub }: any): JSX.Element => {
  const { t } = useTranslation();
  const [sectionData, setSectionData] = useState<any>();

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['checks'],
    queryFn: () => {
      return getChecksRequests(hub);
    },
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const {
      stockCheckP0,
      freshChecksRoutine,
      bbdChecksRoutine,
      stockCheckP1,
      manualCheck,
    } = splitChecksData(data);

    setSectionData({
      stockCheckP0: stockCheckP0 || [],
      freshChecksRoutine: freshChecksRoutine || [],
      bbdChecksRoutine: bbdChecksRoutine || [],
      stockCheckP1: stockCheckP1 || [],
      manualCheck: manualCheck || [],
    });
  }, [data]);

  const urgentSection = useMemo(() => {
    const stockCheckP0 = sectionData?.stockCheckP0;
    if (stockCheckP0?.length === 0 || !stockCheckP0) {
      return [];
    }

    const oldestTask = findOldestTask(stockCheckP0);

    const urgentStockChecks: Check = {
      remaining_checks_count: stockCheckP0?.length,
      section: ChecksSections.URGENT,
      type: ChecksTypes.STOCK_CHECK_P0,
      deadline_type: ChecksDeadlineTypes.SLA,
      deadline_at: oldestTask!.deadline_at,
      oldest_task_created_date: oldestTask.created_at,
    };

    return [urgentStockChecks];
  }, [sectionData?.stockCheckP0]);

  const freshChecksSection: Check = useMemo(() => {
    const freshChecksRoutine = sectionData?.freshChecksRoutine;
    if (!freshChecksRoutine) {
      return setChecksPayload(ChecksTypes.FRESH_CHECK, null);
    } else {
      return setChecksPayload(ChecksTypes.FRESH_CHECK, freshChecksRoutine);
    }
  }, [sectionData?.freshChecksRoutine]);

  const bbdChecksSection: Check = useMemo(() => {
    const bbdChecksRoutine = sectionData?.bbdChecksRoutine;
    if (!bbdChecksRoutine) {
      return setChecksPayload(ChecksTypes.BBD_CHECK, null);
    } else {
      return setChecksPayload(ChecksTypes.BBD_CHECK, bbdChecksRoutine);
    }
  }, [sectionData?.bbdChecksRoutine]);

  const stockChecksP1: Check = useMemo(() => {
    const stockCheckP1 = sectionData?.stockCheckP1;
    if (!stockCheckP1) {
      return setChecksPayload(ChecksTypes.STOCK_CHECK_P1, null);
    } else {
      return setChecksPayload(ChecksTypes.STOCK_CHECK_P1, stockCheckP1);
    }
  }, [sectionData?.stockCheckP1]);

  const manualChecks = useMemo(() => {
    const manualCheck = sectionData?.manualCheck;
    if (!manualCheck) {
      return setChecksPayload(ChecksTypes.MANUAL_CHECK, null);
    } else {
      return setChecksPayload(ChecksTypes.MANUAL_CHECK, manualCheck);
    }
  }, [sectionData?.manualCheck]);

  const dailyChecksCompleted = useMemo(() => {
    const remainingChecks =
      (freshChecksSection?.remaining_checks_count as number) +
      (bbdChecksSection?.remaining_checks_count as number) +
      (stockChecksP1?.remaining_checks_count as number);

    const totalDailyChecks =
      (freshChecksSection?.all_checks_count as number) +
      (bbdChecksSection?.all_checks_count as number) +
      (stockChecksP1?.all_checks_count as number);

    return {
      percentage: calculatePercentage(remainingChecks, totalDailyChecks),
    };
  }, [freshChecksSection, bbdChecksSection, stockChecksP1]);

  if (isLoading) {
    return (
      <div className="w-[300px] h-[700px]">
        <CardSkeleton />
      </div>
    );
  }

  if (error) {
    const onClick = (): Promise<any> => refetch();
    return (
      <div className="w-[300px] h-[700px]">
        <CardError onClick={onClick} />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-[500px]">
      <Heading title="Checks" />
      <div className="bg-[#23272A] rounded-[10px] mt-2 py-5 px-4 text-sm text-white h-[700px] relative">
        <div>
          <div className="flex justify-between mb-2 items-center">
            <span className="">{t('checks.urgent.title')}</span>
            <span
              className={clsx(
                'text-xs',
                urgentSection.length ? 'text-white' : 'text-[#BFBFBF]'
              )}
            >
              {urgentSection.length
                ? t('checks.urgent.info')
                : t('checks.urgent.info.nodata')}
            </span>
          </div>
          {urgentSection.length > 0 &&
            urgentSection.map((section: any) => (
              <ChecksCard
                key={section.type}
                type={section.type}
                remainingChecksCount={section.remaining_checks_count}
                oldestTaskCreatedDate={section.oldest_task_created_date}
              />
            ))}
        </div>
        <div className="mt-6">
          <div className="flex justify-between mb-2">
            <span>{t('checks.routine.title')}</span>
          </div>
          {[freshChecksSection, bbdChecksSection].map((section: any) => (
            <ChecksCard
              key={section?.type}
              type={section?.type}
              remainingChecksCount={section?.remaining_checks_count}
              completedChecksCount={section?.completed_cheks_count}
              completedPercentage={section?.completed_percentage}
              isDeadlinePassed={section?.is_deadline_passed}
            />
          ))}
        </div>
        <div className="mt-6">
          <div className="flex justify-between mb-2">
            <span>{t('checks.other')}</span>
          </div>
          {[stockChecksP1, manualChecks].map((section: any, i: number) => (
            <ChecksCard
              key={section.type}
              type={section.type}
              remainingChecksCount={section.remaining_checks_count}
              completedPercentage={section.completed_percentage}
              completedChecksCount={section.completed_checks_count}
            />
          ))}
        </div>
        <div className="w-[470px] text-base absolute bottom-3">
          <div className="-mb-3">
            {dailyChecksCompleted?.percentage}%{' '}
            <span className="text-[#BFBFBF]">
              {t('checks.daily_check.completed')}
            </span>
          </div>
          {
            <ProgressBar
              width={dailyChecksCompleted?.percentage}
              color={clsx(
                dailyChecksCompleted?.percentage === 100
                  ? 'bg-[#66C8BA]'
                  : 'bg-[#FFE8D0]'
              )}
              height={2}
              backgroundColor="bg-[#3A4045]"
            />
          }
        </div>
      </div>
    </div>
  );
};

export default Checks;
