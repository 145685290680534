import { useEffect, useMemo, useState } from 'react';
import WidgetView from '../flow-management-hub-level/widget-view';
import { useQuery } from '@tanstack/react-query';

import { getChecksRequests, getHubKpis } from 'utils/network/apis';
import Heading from './heading';
import { MetricCard } from './metric-card';
import { useTranslation } from 'react-i18next';
import {
  ChecksDeadlineTypes,
  ChecksSections,
  ChecksTypes,
  OAStates,
  RiderStates,
} from 'utils/constants';
import { Icon, ProgressBar, ProgressBarProps } from '@hubportal/components';
import {
  findOldestTask,
  formatNumberToPercentage,
  setChecksPayload,
  splitChecksData,
  timeToMinutesFormatter,
} from 'utils/helpers';
import WorkforceSection, {
  GroupedWorforce,
  Workforce,
} from './workforce-section';
import { LearnMoreModal } from 'components/LearnMoreModal';
import {
  isEppoHubMetricsEnabled,
  isFeatureEnabledEppo,
} from 'utils/eppo/helpers';
import useStore from 'utils/store';
import ridingTooltipSections from 'components/TooltipSectionItem/ridingTooltipSections';
import OATooltipSections from 'components/TooltipSectionItem/OATooltipSections';
import { FEATURE_FLAGS, FEATURE_VARIANTS } from 'utils/eppo/flags';
import OpsAssociatesV2 from 'components/OpsAssociatesV2';

type HubLevelProps = {
  hubSlug: string;
  authClient: string;
};

const inactiveStates = new Set([
  RiderStates.TEMP_OFFLINE,
  RiderStates.IDLE,
  RiderStates.ON_BREAK,
  RiderStates.STARTING,
]);

const HubLevel: React.FC<HubLevelProps> = ({
  hubSlug,
  authClient,
}: HubLevelProps) => {
  const { featureFlagDecisions, isFullScreen } = useStore();
  const [sectionData, setSectionData] = useState<any>();
  const [isInventoryWidgetActive, setIsInventoryWidgetActive] = useState(true);
  const [infoModal, setInfoMoreModal] = useState<string>('');

  const { t } = useTranslation();

  const isHubLevelVariableEnabled = useMemo(() => {
    return isEppoHubMetricsEnabled(featureFlagDecisions);
  }, [featureFlagDecisions]);

  const { data } = useQuery({
    queryKey: ['get-hub-kpis'],
    queryFn: () => getHubKpis(hubSlug),
    refetchInterval: 10000,
  });

  const { data: checkData } = useQuery({
    queryKey: ['checks'],
    queryFn: () => {
      return getChecksRequests(hubSlug);
    },
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (!checkData) {
      return;
    }
    const { stockCheckP0, freshChecksRoutine, bbdChecksRoutine, stockCheckP1 } =
      splitChecksData(checkData);

    setSectionData({
      stockCheckP0: stockCheckP0 || [],
      freshChecksRoutine: freshChecksRoutine || [],
      bbdChecksRoutine: bbdChecksRoutine || [],
      stockCheckP1: stockCheckP1 || [],
    });
  }, [checkData]);

  const urgentSection = useMemo(() => {
    const stockCheckP0 = sectionData?.stockCheckP0;
    if (stockCheckP0?.length === 0 || !stockCheckP0) {
      return [];
    }

    const oldestTask = findOldestTask(stockCheckP0);

    const urgentStockChecks: Check = {
      remaining_checks_count: stockCheckP0?.length,
      section: ChecksSections.URGENT,
      type: ChecksTypes.STOCK_CHECK_P0,
      deadline_type: ChecksDeadlineTypes.SLA,
      deadline_at: oldestTask!.deadline_at,
      oldest_task_created_date: oldestTask.created_at,
    };

    return [urgentStockChecks];
  }, [sectionData?.stockCheckP0]);

  const freshChecksSection: Check = useMemo(() => {
    const freshChecksRoutine = sectionData?.freshChecksRoutine;
    if (!freshChecksRoutine) {
      return setChecksPayload(ChecksTypes.FRESH_CHECK, null);
    } else {
      return setChecksPayload(ChecksTypes.FRESH_CHECK, freshChecksRoutine);
    }
  }, [sectionData?.freshChecksRoutine]);

  const bbdChecksSection: Check = useMemo(() => {
    const bbdChecksRoutine = sectionData?.bbdChecksRoutine;
    if (!bbdChecksRoutine) {
      return setChecksPayload(ChecksTypes.BBD_CHECK, null);
    } else {
      return setChecksPayload(ChecksTypes.BBD_CHECK, bbdChecksRoutine);
    }
  }, [sectionData?.bbdChecksRoutine]);

  const stockChecksP1: Check = useMemo(() => {
    const stockCheckP1 = sectionData?.stockCheckP1;
    if (!stockCheckP1) {
      return setChecksPayload(ChecksTypes.STOCK_CHECK_P1, null);
    } else {
      return setChecksPayload(ChecksTypes.STOCK_CHECK_P1, stockCheckP1);
    }
  }, [sectionData?.stockCheckP1]);

  const groupRidersByStatus = (riders: Workforce[]): GroupedWorforce[] => {
    const grouped = riders.reduce(
      (acc, rider) => {
        switch (rider.activity) {
          case RiderStates.ONLINE:
            acc.online.push(rider);
            break;
          case RiderStates.RETURNING:
            acc.returning.push(rider);
            break;
          case RiderStates.BUSY:
            acc.busy.push(rider);
            break;
          default:
            if (inactiveStates.has(rider.activity as RiderStates)) {
              acc.inactive.push(rider);
            }
            break;
        }
        return acc;
      },
      {
        online: [],
        returning: [],
        busy: [],
        inactive: [],
      } as { [key: string]: Workforce[] }
    );

    return [
      { status: 'online', workforce: grouped.online },
      { status: 'returning', workforce: grouped.returning },
      { status: 'busy', workforce: grouped.busy },
      { status: 'inactive', workforce: grouped.inactive },
    ];
  };

  const groupedRiders = groupRidersByStatus(data?.riding?.riders ?? []);

  const groupOpsAssociatesByStatus = (
    opsAssociates: Workforce[],
    isInventoryActive: boolean
  ): GroupedWorforce[][] => {
    const grouped: { [key: string]: Workforce[] } = {
      picking: [],
      inbounding: [],
      inventory: [],
      restocking: [],
      hub_activities: [],
      idle: [],
    };

    opsAssociates.forEach((opsAssociate) => {
      switch (opsAssociate.activity) {
        case OAStates.PICKING:
          grouped.picking.push(opsAssociate);
          break;
        case OAStates.INBOUNDING:
          grouped.inbounding.push(opsAssociate);
          break;
        case OAStates.INVENTORY:
          grouped.inventory.push(opsAssociate);
          break;
        case OAStates.RESTOCKING:
          grouped.restocking.push(opsAssociate);
          break;
        case OAStates.HUB_ACTIVITIES:
          grouped.hub_activities.push(opsAssociate);
          break;
        case OAStates.IDLE:
          grouped.idle.push(opsAssociate);
          break;
        default:
          break;
      }
    });

    if (isInventoryActive) {
      return [
        [
          { status: 'picking', workforce: grouped.picking },
          { status: 'hub_activities', workforce: grouped.hub_activities },
          { status: 'idle', workforce: grouped.idle },
        ],
        [
          { status: 'inbounding', workforce: grouped.inbounding },
          { status: 'inventory', workforce: grouped.inventory },
          { status: 'restocking', workforce: grouped.restocking },
        ],
      ];
    } else {
      return [
        [
          { status: 'picking', workforce: grouped.picking },
          { status: 'inbounding', workforce: grouped.inbounding },
          { status: 'inventory', workforce: grouped.inventory },
          { status: 'restocking', workforce: grouped.restocking },
          { status: 'hub_activities', workforce: grouped.hub_activities },
          { status: 'idle', workforce: grouped.idle },
        ],
      ];
    }
  };

  const groupedOpsAssociates = groupOpsAssociatesByStatus(
    data?.inside_hub?.ops_associates ?? [],
    isInventoryWidgetActive
  );

  const ridingKpi = {
    mainMetric: {
      title: t('employee_metrics.metric.deliveries_per_hour'),
      value: data?.riding.deliveries_per_hour?.value ?? 0,
      link: 'employee-level?kpi=riding&metric=deliveries_per_hour&sort_by=ASC',
    },
    metrics: [
      {
        title: t('employee_metrics.metric.acceptance_rate'),
        value: formatNumberToPercentage(data?.riding.acceptance_rate?.value),
        link: 'employee-level?kpi=riding&metric=acceptance_rate&sort_by=ASC',
      },
      {
        title: t('employee_metrics.metric.avg_time_at_customer_in_seconds'),
        value: timeToMinutesFormatter(
          data?.riding.avg_time_at_customer_in_seconds?.value ?? 0
        ),
        link: 'employee-level?kpi=riding&metric=avg_time_at_customer_in_seconds&sort_by=DESC',
      },
      {
        title: t('employee_metrics.metric.unaccounted_rider_time_offline'),
        value: formatNumberToPercentage(
          data?.riding.unaccounted_time_offline_rate?.value
        ),
        link: 'employee-level?kpi=riding&metric=unaccounted_time_offline&sort_by=DESC',
      },
      {
        title: t('employee_metrics.metric.trip_on_time_compliance'),
        value: formatNumberToPercentage(
          data?.riding.trip_on_time_compliance?.value
        ),
        link: 'employee-level?kpi=riding&metric=trip_on_time_compliance&sort_by=ASC',
      },
    ],
  };

  const insideHubKpi = {
    mainMetric: {
      title: t('employee_metrics.metric.picking_time_per_item_in_seconds'),
      value: timeToMinutesFormatter(
        data?.inside_hub.picking_time_per_item_in_seconds?.value ?? 0
      ),
      link: 'employee-level?kpi=inside-hub&metric=avg_picking_speed_per_item_in_seconds&sort_by=DESC',
    },
    metrics: [
      {
        title: t('employee_metrics.metric.pre_delivery_issue_rate'),
        value: formatNumberToPercentage(
          data?.inside_hub.pre_delivery_issue_rate?.value
        ),
        link: 'employee-level?kpi=inside-hub&metric=pre_delivery_issue_rate&sort_by=DESC',
      },
    ],
  };

  const isOpsAssociatesV2Enabled = useMemo(() => {
    return isFeatureEnabledEppo(
      featureFlagDecisions,
      FEATURE_FLAGS.OF_POOLS_WIDGET,
      FEATURE_VARIANTS.ON
    );
  }, [featureFlagDecisions]);

  return (
    <>
      {isHubLevelVariableEnabled ? (
        <div className="h-full flex py-4 mx-10 cursor-default">
          <div className="grow h-full">
            <Heading
              title={t(`employee_level_metrics.option_filter.rider`)}
              icon="bicycle"
              isInfoExist={true}
            >
              <span
                className="ml-2 text-[#BFBFBF] cursor-pointer"
                onClick={() => setInfoMoreModal('riding')}
              >
                <Icon type="infoCircle" />
              </span>
              {infoModal === 'riding' && (
                <LearnMoreModal
                  open={infoModal === 'riding'}
                  onClose={() => setInfoMoreModal('')}
                  tooltipSections={ridingTooltipSections}
                  modalTitle={t('learn_more.riding.title')}
                />
              )}
            </Heading>
            <MetricCard
              workforceList={groupedRiders}
              mainMetric={ridingKpi.mainMetric}
              otherMetrics={ridingKpi.metrics}
              hasRider={true}
            />
          </div>
          <div className="md:ml-2 lg:ml-4 xl:ml-8 grow">
            <Heading
              title={t(`employee_level_metrics.option_filter.oa`)}
              icon="package"
              isInfoExist={true}
            >
              <span
                className="ml-2 text-[#BFBFBF] cursor-pointer"
                onClick={() => setInfoMoreModal('oa')}
              >
                <Icon type="infoCircle" />
              </span>
              {infoModal === 'oa' && (
                <LearnMoreModal
                  open={infoModal === 'oa'}
                  onClose={() => setInfoMoreModal('')}
                  tooltipSections={OATooltipSections}
                  modalTitle={t('learn_more.oa.title')}
                />
              )}
            </Heading>
            <MetricCard
              workforceList={groupedOpsAssociates[0]}
              mainMetric={insideHubKpi.mainMetric}
              otherMetrics={insideHubKpi.metrics}
              isInventoryWidgetActive={isInventoryWidgetActive}
              urgentCheckCount={urgentSection[0]?.remaining_checks_count ?? 0}
              setIsInventoryWidgetActive={setIsInventoryWidgetActive}
            />
          </div>

          {isInventoryWidgetActive && (
            <div className="md:ml-2 lg:ml-4 xl:ml-8 grow">
              <Heading title="Inventory">
                <button
                  onClick={() => setIsInventoryWidgetActive(false)}
                  className="flex items-center detail-l text-white font-flink rounded-md px-3 py-1 bg-[#23272A] z-10"
                >
                  <span className="mr-2 text-base">
                    {t('hub_metrics.hide')}
                  </span>
                  <Icon type="eyeHide" />
                </button>
              </Heading>
              <div className="h-full">
                <div
                  className={`grow bg-primary rounded-lg ${
                    isFullScreen ? 'h-[75vh]' : 'h-[65vh] lg:h-[73vh]'
                  }`}
                >
                  <div className="border-b-[1px] border-secondary grid grid-flow-col">
                    <WorkforceSection workforceList={groupedOpsAssociates[1]} />
                  </div>
                  <div
                    className={`flex flex-col ${
                      isFullScreen ? 'p-8' : 'md:p-2 lg:p-6'
                    }`}
                  >
                    <div className="flex flex-col text-white">
                      <h3 className="text-2xl font-bold">
                        {urgentSection[0]?.remaining_checks_count ?? 0}
                      </h3>
                      <span className="text-base font-normal mt-1">
                        {t('checks.urgent.title')}
                      </span>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-[#BFBFBF]">
                        {t('checks.daily.title')}
                      </h3>
                      <div className="text-white sm:mt-1 md:mt-4">
                        <h3>{t(`checks.fresh_check`)}</h3>
                        <div className="flex flex-row items-center">
                          <div className="w-full">
                            <ProgressBar
                              width={
                                freshChecksSection.completed_percentage as ProgressBarProps['width']
                              }
                              color="bg-[#E3F3CF]"
                              height={2}
                              backgroundColor="bg-[#3A4045]"
                            />
                          </div>
                          <span className="ml-1">
                            {freshChecksSection.completed_percentage === 100 ? (
                              <span className="text-[#008040]">
                                <Icon type="verification" />
                              </span>
                            ) : (
                              <span>
                                {`${
                                  freshChecksSection?.completed_checks_count ??
                                  0
                                }/${freshChecksSection?.all_checks_count ?? 0}`}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="text-white mt-1 lg:mt-3">
                        <h3>{t(`checks.bbd_check`)}</h3>
                        <div className="flex flex-row items-center">
                          <div className="w-full">
                            <ProgressBar
                              width={
                                bbdChecksSection.completed_percentage as ProgressBarProps['width']
                              }
                              color="bg-[#E5DBF3]"
                              height={2}
                              backgroundColor="bg-[#3A4045]"
                            />
                          </div>
                          <span className="ml-1">
                            {bbdChecksSection.completed_percentage === 100 ? (
                              <span className="text-[#008040]">
                                <Icon type="verification" />
                              </span>
                            ) : (
                              <span>
                                {`${
                                  bbdChecksSection?.completed_checks_count ?? 0
                                }/${bbdChecksSection?.all_checks_count ?? 0}`}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="text-white mt-1 lg:mt-3">
                        <h3>{t(`checks.stock_check_p1`)}</h3>
                        <div className="flex flex-row items-center">
                          <div className="w-full">
                            <ProgressBar
                              width={
                                stockChecksP1.completed_percentage as ProgressBarProps['width']
                              }
                              color="bg-[#FBEBCC]"
                              height={2}
                              backgroundColor="bg-[#3A4045]"
                            />
                          </div>
                          <span className="ml-1">
                            {stockChecksP1.completed_percentage === 100 ? (
                              <span className="text-[#008040]">
                                <Icon type="verification" />
                              </span>
                            ) : (
                              <span>
                                {`${
                                  stockChecksP1?.completed_checks_count ?? 0
                                }/${stockChecksP1?.all_checks_count ?? 0}`}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isOpsAssociatesV2Enabled && (
            <div className="grow md:ml-2 lg:ml-4 xl:ml-8">
              <Heading title={t('ops.title')} />
              <div className="h-full">
                <OpsAssociatesV2 hub={hubSlug} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <WidgetView hubSlug={hubSlug} authClient={authClient} />
      )}
    </>
  );
};

export default HubLevel;
