import { CardError, Heading } from 'components/Card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Parcel from 'single-spa-react/parcel';

const ridersWidgetUrl = process.env.REACT_RIDERS_WIDGET_URL || '';

const RidersWidget = ({ authClient }: PropsWithAuth0Client): JSX.Element => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  if (error) {
    return (
      <div className="w-[300px] h-[680px]" data-testid="card-error">
        <CardError />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-[390px] h-[680px]">
      <Heading title={t('riders.title')} />
      <div className="bg-[#23272A] border-[#23272A] border-2 rounded-[10px] mt-2 py-2">
        <Parcel
          wrapClassName="w-96 h-[680px]"
          authClient={authClient}
          handleError={() => {
            setError(true);
          }}
          config={async () => {
            return System.import(ridersWidgetUrl);
          }}
          mapEnabled={false}
          titleEnabled={false}
        />
      </div>
    </div>
  );
};

export default RidersWidget;
